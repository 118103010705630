<template>
  <div>
    <div class="mail-title background__bg_add_document d-flex is-justify-content-space-between">
      <span class="color__blue_main fs-20 fw__bold">{{ $t("mails.title") }}</span>
      <div class="column p__bottom--0 p__top--0
      p__right--0 is-half has-text-right d-flex align-items-center is-justify-content-flex-end">
        <button
            class="button mr-3 p-0 color__white btn-delete-mail align-items-center fs-14 is-justify-content-start"
            v-if="hasChecked"
            @click="deleteAllTemplate"
        ><img width="18px" src="@/assets/svgs/DocDetail/ic_delete2.svg" class="m__right--15"/>
          <span class="fs-12">{{ $t("buttons.delete") }}</span>
        </button>
        <button
            @click="addMailTemplate()"
            class="button p-0 color__white
              background__blue_main btn-create fs-14 is-justify-content-start align-items-center"
        ><img width="18px" src="@/assets/svgs/ic_add_file_1.svg" class="m__right--15"/>
          <span class="fs-12">{{ $t("mails.buttons.create") }}</span>
        </button>
      </div>
    </div>
    <div class="page-main-content">
      <div class="columns d-flex pt-3">
        <div
            class="column p__bottom--0 p__top--0 align-self-center is-half-mobile is-half-desktop
             is-half-fullhd is-half-tablet is-half-widescreen is-half-touch d-flex">
          <span class="vertical-baseline-middle fs-12 color__gray_text_1">全{{ paginate.total || 0 }}件</span>
        </div>
        <div
            class="column p__bottom--0 p__top--0
            has-text-right align-items-center is-half-mobile
            is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch
            d-flex is-justify-content-flex-end">
            <span class="m__right--5 vertical-baseline-middle fs-12 color__gray_text_1">{{ $t('page.page-range') }}</span>
          <div
              class="dropdown is-right"
              id="dropdown-pagination"
              v-click-out="closeDropPageRange"
          >
            <div class="dropdown-trigger align-items-center d-flex">
              <button
                  class="button btn-change-page-range p__top--0 p__bottom--0 p__left--10"
                  aria-haspopup="true"
                  @click="toggleDropPageRange()"
              >
                <span class="has-text-centered w--80 p__right--5 fs-12 color__gray_text_1">{{ paginate.perPage }} 件</span>
                <span class="icon is-small">
                  <img width="8px" src="../../assets/svgs/icon_drop.svg"/>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a
                    @click="changePageRange(e)"
                    v-for="(e, i) in pageRanges"
                    :key="'page-range-' + i"
                    class="dropdown-item p__right--10 fs-12 color__gray_text_1"
                >
                  {{ e }} 件
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column p__top--0">
          <div class="table-container data-table">
            <table class="table is-striped is-hoverable is-fullwidth">
              <thead>
              <tr>
                <th class="input-title col-checkbox">
                  <label class="checkbox d-flex">
                    <input
                        @change="toggleCheckAll()"
                        type="checkbox"
                        class="is-radiusless"
                        :disabled="disabledAll"
                        v-model="checkAll"
                    />
                  </label>
                </th>
                <th class="input-title p--12">
                  {{ $t("mails.input_field.name.label") }}
                </th>
                <th class="input-title p--12 created_at">
                  {{ $t("mails.input_field.create_at") }}
                </th>
                <th class="input-title p--12">
                  {{ $t("mails.input_field.author") }}
                </th>
                <th class="input-title p--12 col-action">
                  {{ $t("mails.input_field.action") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr :key="'user' + i" v-for="(e, i) in mailTemplate">
                <td class="">
                  <label class="checkbox d-flex is-justify-content-start">
                    <input
                        type="checkbox"
                        class="is-radiusless"
                        :disabled="e.is_system === 1 || e.is_used"
                        v-model="e.checked"
                        @change="checkItem(e)"
                    />
                  </label>
                </td>
                <td class="template-name">{{ e.name }}</td>
                <td class="">
                  {{ momentFormat(e.created_at) }}
                </td>
                <td class="">{{ e.user_created.name }}</td>
                <td class="">
                  <div class="d-flex is-justify-content-start">
                    <button
                        :data-tooltip="$t('mails.buttons.edit')"
                        @click="updateMailTemplate(e.id)"
                        class="blob is-action action-success m__right--10 has-tooltip-top btn-edit-mail"
                    >
                      <img width="25px" src="@/assets/svgs/DocDetail/ic_edit_1.svg"/>
                    </button>
                    <button
                        :data-tooltip="(e.is_system !== 1 && !e.is_used && displayTooltip) && $t('mails.buttons.delete_icon')"
                        :disabled="e.is_system === 1 || e.is_used"
                        @click="deleteTemplate(e)"
                        :class="{'disabled': e.is_system === 1 || e.is_used}"
                        class="blob is-action has-tooltip-top btn-delete-list-mail"
                    >
                      <img width="25px" src="@/assets/svgs/DocDetail/ic_delete.svg"/>
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-if="!mailTemplate || !mailTemplate.length">
                <td class="has-text-centered" colspan="7">
                  {{ $t("target_not_found") }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="columns p__top--20">
        <div class="column is-full has-text-centered">
          <pagination
              :current="paginate.currentPage"
              :per-page="paginate.perPage"
              :total="paginate.total"
              @change="changePage($event)"
          >
          </pagination>
        </div>
      </div>
    </div>
    <div class="modal" id="modal-add-mail">
      <div class="modal-background"></div>
      <div class="modal-card">
        <template>
          <div class="modal-card-head background__gray_dark">
            <p class="modal-card-title input-title color__blue_main text-center fs-20 fw__bold">{{
                $t('mail_template.modal.title')
              }}</p>
            <button class="delete" aria-label="close" @click="closeModal('modal-add-mail')"></button>
          </div>
          <section class="modal-card-body change-role-office">
            <div class="modal-field-content">
              <div class="m__bottom--18">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_main fw__bold">{{$t('mails.input_field.name.label')}}<i
                      :data-tooltip="$t('tooltip.required')"
                      class="required-note">*</i></span>
                </div>
                <div class="">
                  <label>
                    <input
                        class="input"
                        :placeholder="$t('mails.input_field.name.placeholder')"
                        type="text"
                        v-model="mailTemplates.name"
                        >
                  </label>
                  <template v-if="vuelidate.mailTemplates.$dirty">
                      <span class="error pt-1" v-if="!vuelidate.mailTemplates.name.required">
                        {{ $t('mails.messages.validation.name_required') }}
                      </span>
                      <span class="error pt-1" v-if="!vuelidate.mailTemplates.name.maxLength">
                        {{ $t('mails.messages.validation.name_maxlength') }}
                      </span>
                  </template>
                </div>
              </div>
              <div class="m__bottom--18 ">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_main fw__bold">{{$t('mails.input_field.title.label')}}
                  <i :data-tooltip="$t('tooltip.required')"
                     class="required-note">*</i></span>
                </div>
                <div class="">
                    <label>
                      <input
                          class="input"
                          :placeholder="$t('mails.input_field.title.placeholder')"
                          type="text"
                          v-model="mailTemplates.subject"
                          >
                    </label>
                    <template v-if="vuelidate.mailTemplates.$dirty">
                       <span class="error pt-1" v-if="!vuelidate.mailTemplates.subject.required">
                          {{ $t('mails.messages.validation.subject_required') }}
                       </span>
                        <span class="error pt-1" v-if="!vuelidate.mailTemplates.subject.maxLength">
                         {{ $t('mails.messages.validation.subject_maxlength') }}
                        </span>
                    </template>
                </div>
              </div>
              <div class="m__bottom--18">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 fw__bold color__blue_main">{{$t('mails.input_field.content.label')}}<i
                      :data-tooltip="$t('tooltip.required')"
                      class="required-note">*</i></span>
                </div>
                <div class="">
                  <label>
                    <editor
                            v-model.trim="mailTemplates.body"
                            class="body"
                            id="body"
                            name="body"
                            :other_options="customToolbar"
                            :toolbar1="toolbar1"
                            :plugins="plugins"
                    >
                    </editor>
                  </label>
                  <template v-if="vuelidate.mailTemplates.$dirty">
                      <span class="error pt-1" v-if="!vuelidate.mailTemplates.body.required">
                        {{ $t('mails.messages.validation.body_required') }}
                      </span>
                  </template>
                </div>
              </div>
            </div>
          </section>
          <div class="modal-card-foot is-justify-content-center background__white p__bottom--50">
            <button @click="createMailTemplate"
                    class="button background__blue_main close-modal p-0 btn-save">
              {{ $t('buttons.submit') }}
            </button>
            <button @click="closeModal('modal-add-mail');"
                    class="button close-modal btn-back background__white_pink">
              {{ $t('buttons.cancel_add') }}
            </button>
          </div>
        </template>
      </div>
    </div>
    <div class="modal" id="modal-edit-mail">
      <div class="modal-background"></div>
      <div class="modal-card">
        <template>
          <div class="modal-card-head background__gray_dark">
            <p class="modal-card-title input-title color__blue_main text-center fs-20 fw__bold">{{
                $t('mail_template.modal.edit_title')
              }}</p>
            <button class="delete" aria-label="close" @click="closeModal('modal-edit-mail')"></button>
          </div>
          <section class="modal-card-body change-role-office">
            <div class="modal-field-content">
              <div class="m__bottom--18">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_main fw__bold">{{$t('mails.input_field.name.label')}}<i
                      :data-tooltip="$t('tooltip.required')"
                      class="required-note">*</i></span>
                </div>
                <div class="">
                  <label>
                    <input
                        class="input"
                        :placeholder="$t('mails.input_field.name.placeholder')"
                        type="text"
                        v-model="mailTemplateUpdate.name"
                    >
                  </label>
                  <template v-if="vuelidate.mailTemplateUpdate.$dirty">
                      <span class="error pt-1" v-if="!vuelidate.mailTemplateUpdate.name.required">
                        {{ $t('mails.messages.validation.name_required') }}
                      </span>
                    <span class="error pt-1" v-if="!vuelidate.mailTemplates.name.maxLength">
                        {{ $t('mails.messages.validation.name_maxlength') }}
                      </span>
                  </template>
                </div>
              </div>
              <div class="m__bottom--18 ">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_main fw__bold">{{$t('mails.input_field.title.label')}}
                  <i :data-tooltip="$t('tooltip.required')"
                     class="required-note">*</i></span>
                </div>
                <div class="">
                  <label>
                    <input
                        class="input"
                        :placeholder="$t('mails.input_field.title.placeholder')"
                        type="text"
                        v-model="mailTemplateUpdate.subject"
                    >
                  </label>
                  <template v-if="vuelidate.mailTemplateUpdate.$dirty">
                       <span class="error pt-1" v-if="!vuelidate.mailTemplateUpdate.subject.required ">
                          {{ $t('mails.messages.validation.subject_required') }}
                       </span>
                    <span class="error pt-1" v-if="!vuelidate.mailTemplateUpdate.subject.maxLength">
                         {{ $t('mails.messages.validation.subject_maxlength') }}
                        </span>
                  </template>
                </div>
              </div>
              <div class="m__bottom--18">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 fw__bold color__blue_main">{{$t('mails.input_field.content.label')}}<i
                      :data-tooltip="$t('tooltip.required')"
                      class="required-note">*</i></span>
                </div>
                <div class="">
                  <label>
                    <editor
                        v-model.trim="mailTemplateUpdate.body"
                        class="body"
                        id="body-edit"
                        name="body"
                        :other_options="customToolbar"
                        :toolbar1="toolbar1"
                        :plugins="plugins"
                    >
                    </editor>
                  </label>
                  <template v-if="vuelidate.mailTemplateUpdate.$dirty">
                      <span class="error pt-1" v-if="!vuelidate.mailTemplateUpdate.body.required">
                        {{ $t('mails.messages.validation.body_required') }}
                      </span>
                  </template>
                </div>
              </div>
            </div>
          </section>
          <div class="modal-card-foot is-justify-content-center background__white p__bottom--50">
            <button @click="editMailTemplate()"
                    class="button background__blue_main close-modal p-0 btn-save">
              {{ $t('buttons.submit') }}
            </button>
            <button @click="closeModal('modal-edit-mail');"
                    class="button close-modal btn-back background__white_pink">
              {{ $t('buttons.cancel_add') }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {ACTION_SET_ACTIVE_SIDEBAR, ACTION_SET_SEARCH_PARAMS} from "@/stores/common/actions";
import {MailTemplateService} from "@/services";
import {momentFormat} from "@/filters";
import findIndex from "lodash/findIndex";
import tinymce from 'vue-tinymce-editor'
import i18n from "../../lang/i18n";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  name: "MailTemplateList",
  data(vm) {
    return {
      mailTemplates: {
        name: '',
        subject: '',
        body: '',
        code: '',
      },
      mailTemplateUpdate: {
        id: '',
        name: '',
        subject: '',
        body: '',
      },
      error_server: {},
      mailTemplate: [],
      paginate: {
        currentPage: null,
        lastPage: null,
        perPage: null,
        total: null,
      },
      pageRanges: [10, 20, 50, 100],
      checkAll: false,
      hasChecked: false,
      pushItem: [],
      displayTooltip: true,
      isValidBody: false,
      disabledAll: true,
      customToolbar: {
        setup: function (editor) {
          let placeholder = '<p style="color: #ccc;" id="editor-placeholder">' + i18n.t('mails.input_field.content.placeholder') + '</p>'
          let mceContent = document.getElementsByClassName('mce-tinymce')
          let content = '<p>&nbsp;</p>'
          editor.on('init', function () {
            editor.setContent(placeholder);
          });
          editor.on('click', function () {
            mceContent[0].classList.add('hover-editor')
            let index = editor.getContent().includes(placeholder)
            if(index) {
              localStorage.setItem('isValidBody','true')
            }else {
              localStorage.setItem('isValidBody','false')
            }
          });
          editor.on('focus', () => {
            if (vm.stringToHTML(editor.getContent()) === i18n.t('mails.input_field.content.placeholder')) {
              editor.setContent('')
            }
          });
          editor.on('keyup', function () {
            if(editor.getContent() === '') {
              localStorage.setItem('isValidBody','false')
            }else {
              localStorage.setItem('isValidBody','true')
            }
          });
          editor.on('blur', function () {
            if( !content || vm.stringToHTML(content) === '&nbsp;') {
              editor.setContent(placeholder)
              localStorage.setItem('isValidBody', 'false')
            }else {
              localStorage.setItem('isValidBody', 'true')
            }
          });
          editor.on('mouseenter', function () {
            mceContent[0].classList.add('hover-editor')
          });
          editor.on('change', function () {
            placeholder = document.getElementById('editor-placeholder')
            content = editor.getContent()
          });
        },
        branding: false,
        height: 200,
        menubar: false,
      },
      toolbar1: 'formatselect | bold italic  strikethrough  forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | code',
      plugins: [
        'visualblocks advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
    };
  },
  validations: {
    mailTemplates: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      subject: {
        required,
        maxLength: maxLength(50)
      },
      body: {
        required: function (val) {
          if (!val) {
            return false;
          }
          return true
        }
      }
    },
    mailTemplateUpdate: {
      name: {
        required,
        maxLength: maxLength(50),
        // XSSCheck: (val) => {
        //   return XSSCheck(val)
        // }
      },
      subject: {
        required,
        maxLength: maxLength(50)
      },
      body: {
        required: function (val) {
          if (!val) {
            return false;
          }
          return true
        }
        // maxLength: maxLength(1000)
      }
    }
  },
  components: {
    'editor': tinymce
  },
  methods: {
    editMailTemplate() {
      this.error_server = {}
      this.vuelidate.$touch();
      if (!this.vuelidate.mailTemplateUpdate.$invalid) {
          MailTemplateService.update(this.mailTemplateUpdate.id, this.mailTemplateUpdate)
              .then(() => {
                this.mailTemplate.map(e => {
                  if(e.id == this.mailTemplateUpdate.id) {
                    e.name = this.mailTemplateUpdate.name
                    e.subject= this.mailTemplateUpdate.subject
                    e.body= this.mailTemplateUpdate.body
                  }
                })
                this.mailTemplate = [...this.mailTemplate]
                this.$toast.success(this.$t('mails.messages.update_success'))
                this.closeModal('modal-edit-mail')
                this.vuelidate.$reset()
              })
              .catch((err) => {
                this.submitAvailable = true
                this.error_server = err.data.errors
                // this.$toast.error(err.data.message)
              })
      }
    },
    stringToHTML(str) {
      let parser = new DOMParser()
      let doc = parser.parseFromString(str, 'text/html')
      return doc.body.firstChild ? doc.body.firstChild.innerHTML : ''
    },
    closeModal(id) {
      if (this.modalOpened) {
        this.$nextTick(() => {
          if (this.$el.querySelector(`#${id}`)) {
            this.$el.querySelector(`#${id}`).classList.remove('is-active')
          }
        })
        setTimeout(() => {
          this.modalOpened = false
          this.displayTooltip = true
        })
      }
    },
    toggleModal(id) {
      this.$nextTick(() => {
        if (this.$el.querySelector(`#${id}`)) {
          this.$el.querySelector(`#${id}`).classList.toggle('is-active')
        }
      })
    },
    addMailTemplate() {
      this.vuelidate.mailTemplates.$reset()
      this.mailTemplates = {
        name: '',
        subject: '',
        body: '',
      }
      this.toggleModal('modal-add-mail')
      setTimeout(() => {
        this.modalOpened = true
      })
    },
    momentFormat(date, format = "YYYY-MM-DD") {
      return momentFormat(date, format);
    },
    closeDropPageRange() {
      this.$el
          .querySelector("#dropdown-pagination")
          .classList.remove("is-active");
    },
    toggleDropPageRange() {
      this.$el
          .querySelector("#dropdown-pagination")
          .classList.toggle("is-active");
    },
    changePageRange(range) {
      this.paginate.perPage = range;
      this.hasChecked = false;
      this.checkAll = false;
      this.closeDropPageRange();
      this.getMailTemplate(1, this.paginate.perPage);
    },
    async getMailTemplate(page, limit) {
      let data = {page, limit}
      this.$store.dispatch(ACTION_SET_SEARCH_PARAMS, {key: 'mailTemplates', data})
      await MailTemplateService.list(page, limit)
          .then((res) => {
            if (res && res.data) {
              let flag = true
              this.mailTemplate = res.data.mail_templates.map((e) => {
                if (!e.is_system && !e.is_used) {
                  flag = false
                }
                e.checked = false
                return e
              })
              this.disabledAll = flag
              this.pushItem = []
              this.paginate = {
                currentPage: res.data.current_page,
                lastPage: res.data.last_page,
                perPage: res.data.per_page * 1,
                total: res.data.total,
              };
            }
          })
          .catch((err) => {
            this.$toast.error(err.data.message);
          });
    },
    changePage(page) {
      this.hasChecked = false;
      this.checkAll = false;
      this.mailTemplate.map((e) => {
        if (e.checked) {
          this.pushItem.splice(this.pushItem.indexOf(e.id));
        }
      });
      this.getMailTemplate(page, this.paginate.perPage);
    },
    createMailTemplate() {
      this.error_server = {}
      this.vuelidate.$touch();
      if (!this.vuelidate.mailTemplates.$invalid) {
          this.mailTemplates.code = Math.random().toString(36).substring(7)
          MailTemplateService.create(this.mailTemplates)
              .then((res) => {
                this.submitAvailable = true
                this.$toast.success(this.$t('mails.messages.create_success'))
                this.closeModal('modal-add-mail')
                ++this.paginate.total
                this.mailTemplate = [res.data, ...this.mailTemplate]
                this.mailTemplate = [...this.mailTemplate]
                this.mailTemplate = this.mailTemplate.slice(0, 10)
                this.vuelidate.$reset()
                this.disabledAll = false
              })
              .catch((err) => {
                this.submitAvailable = true
                this.error_server = err.data.errors
                // this.$toast.error(err.data.message)
              })
      }
    },
    updateMailTemplate(id) {
      let mail = this.mailTemplate.find((item) => item.id == id)
      this.mailTemplateUpdate = {
        id: mail.id,
        name : mail.name,
        subject: mail.subject,
        body: mail.body
      }
      this.mailTemplateUpdate = {...this.mailTemplateUpdate}
      this.toggleModal('modal-edit-mail')
      setTimeout(() => {
        this.modalOpened = true
      })
    },
    toggleCheckAll() {
      this.pushItem = []
      this.hasChecked = false
      this.mailTemplate.map((e) => {
        if (!e.is_system && !e.is_used) {
          e.checked = this.checkAll
        }
        if (e.checked) {
          this.hasChecked = true
        }
      })
      if (this.checkAll) {
        this.mailTemplate.map((e) => {
          if (!e.is_system && !e.is_used) {
            this.pushItem = [...this.pushItem, ...[e.id]]
          }
        })
      }
    },
    checkItem(e) {
      if (!e.checked) {
        this.checkAll = false
        let index = findIndex(this.pushItem, el => el === e.id)
        if (index >= 0) {
          this.pushItem.splice(index, 1)
        }
      } else {
        this.hasChecked = true
        this.pushItem = [...this.pushItem, ...[e.id]]
      }
      if (!this.pushItem.length) {
        this.hasChecked = false
      } else if (this.pushItem.length === this.mailTemplate.filter((e) => !e.is_system && !e.is_used).length) {
        this.checkAll = true
      }
    },
    deleteTemplate(e) {
      if (e.is_system === 1 || e.is_used) {
        this.$toast.warning(
            this.$t("mails.messages.is_system")
        )
      } else {
        this.displayTooltip = false
        this.$popup(
            this.$t("mails.popup.delete.title"),
            this.$t("mails.popup.delete.content"),
            {
              okText: this.$t("mails.popup.delete.ok"),
              cancelText: this.$t("mails.popup.delete.cancel"),
              iconClass: "info-circle",
              type: "warning",
            },
            {
              onOk: async (d) => {
                await MailTemplateService.delete(e.id)
                    .then(() => {
                      this.$toast.success(this.$t("mails.messages.delete_success"));
                      this.getMailTemplate(
                          this.paginate.currentPage,
                          this.paginate.perPage
                      );
                      this.checkAll = false;
                      this.hasChecked = false;
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                d.closeDialog();
              },
              onCancel: (d) => {
                d.closeDialog();
              },
              onClickOut: (d) => {
                d.closeDialog()
                this.displayTooltip = true
              }
            }
        );
      }
    },
    deleteAllTemplate() {
      this.$popup(
          this.$t("mails.popup.delete.title"),
          this.$t("mails.popup.delete.content"),
          {
            okText: this.$t("mails.popup.delete.ok"),
            cancelText: this.$t("mails.popup.delete.cancel"),
            iconClass: "info-circle",
            type: "warning",
          },
          {
            onOk: async (d) => {
              let data = {
                ids: this.pushItem.join(","),
                _method: 'DELETE'
              };

              await MailTemplateService.deleteAll(data)
                  .then(() => {
                    this.checkAll = false
                    this.$toast.success(this.$t("mails.messages.delete_success"));
                    this.getMailTemplate(
                        this.paginate.currentPage,
                        this.paginate.perPage
                    );
                    this.hasChecked = false
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              d.closeDialog();
            },
            onCancel: (d) => {
              d.closeDialog();
            },
          }
      );
    },
  },
  created() {
    localStorage.removeItem('isValidBody')
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, "mail-templates")
    this.paginate.currentPage = 1
    this.paginate.perPage = 10
    let params = this.$store.getters['searchParams'].mailTemplates
    if (params && Object.keys(params).length) {
      this.paginate.currentPage = params.page
      this.paginate.perPage = params.limit
    }
    this.getMailTemplate(this.paginate.currentPage, this.paginate.perPage)
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .mce-tinymce {
    box-shadow: none !important;
  }
  .mce-panel {
    border: 0 solid #E5E5E5;
    background-color: #fff;
    border-radius: 5px;
  }
}
th.input-title {
  text-align: left !important;
}
.mail-title {
  padding: 10px 60px;
  border-radius: 10px 10px 0 0;
}
.page-main-content {
  padding: 20px 60px 50px 60px;
  border-radius: 0 0 10px 10px;
  .columns {
    margin: 0px;
    .column {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }
  }
}
.modal {
  .modal-card {
    max-width: 1024px;
    width: 100%;
    border-radius: 25px;

    .modal-card-body {
      min-height: 400px;
      overflow: hidden;

      .modal-field-content {
        padding: 0 20px;

        .dropdown-content {
          max-height: 200px;
          overflow-y: scroll;
        }
      }
    }

    .change-role-office {
      .modal-field-content {
        padding: 40px 130px;
        min-height: 470px;
        max-height: 550px;
        overflow-y: auto;
      }
    }
  }

  .content-dropdown-version::-webkit-scrollbar {
    display: none !important;
  }

  .action {
    width: 20%
  }

  .column-preview {
    padding: 10px;
    border: 1px solid $blue_main ;
    margin: 20px;
    max-height: 600px;
    min-height: 430px;
    overflow-y: scroll;
    position: relative;

    .preview-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.data-table {
  th {
    background-color: #54B6E7 !important;
  }
}
button {
  &.btn-create {
    border: none;
    border-radius: 13px !important;
    height: 24px !important;
    width: 100px !important;
    padding: 2px 10px 3px 4px !important;
  }
  &.btn-delete-mail {
    border: none;
    border-radius: 13px !important;
    padding: 2px 10px 3px 4px !important;
    width: 100px !important;
    background-color: #DA354A;
    height: 24px !important;
  }
  &.btn-save-small {
    padding: 0 4px !important;
    width: 100px !important;
    height: 25px !important;
  }
  &.btn-change-page-range {
    height: 20px !important;
    border-radius: 10px !important;
    width: 80px !important;
    padding-right: 0px !important;
    &:focus{
      box-shadow: none;
    }
  }
  &.btn-edit-mail {
    border-radius: 100% !important;
    box-shadow: none !important;
    border: none;
  }
  &.btn-delete-list-mail {
    border-radius: 100% !important;
    border: none !important;
    box-shadow: none !important;
    &:hover {
      background-color: #ffffff !important;
      border-color: #dc3449 !important;
    }
  }
  &.disabled {
    opacity: 0.5 !important;
  }
  &.btn-save {
    border-radius: 18px !important;
    border: none !important;
    padding: 0px !important;
    height: 35px !important;
    width: 140px !important;
    font-size: 16px !important;
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
  &.btn-back {
    border-radius: 18px !important;
    border: none !important;
    padding: 0px !important;
    height: 35px !important;
    width: 140px !important;
    font-size: 16px !important;
    color: $btn_back !important;
  }
}
.blob {
  width: 25px;
  height: 25px;
  top: 1px;
  left: 1px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;

  &.disabled {
    background-color: #CCCCCC !important;
    cursor: not-allowed !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.table {
  thead {
    tr {
      th {
        vertical-align: middle;
      }
    }
  }

  tbody {
    tr {
      &.is-checked {
        background-color: #ecd6e5 !important;
      }

      td:not(:last-child) {
        vertical-align: middle;
      }

      .template-name {
        overflow: hidden;
        white-space: nowrap;
        max-width: 100px !important;
        text-overflow: ellipsis;
      }
    }
  }

  .col-action {
    width: 90px;
    max-width: 90px;
  }
}

.col-checkbox {
  width: 5%;
}

.created_at {
  width: 20%;
}
@media screen and (max-width: 768px) {
  .page-list-data {
    .columns {
      .column:first-child,
      .column:nth-child(2) {
        padding: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 495px) {
  button {
    &.is-action.action-success {
      padding: 0 !important;
    }
  }
  .button {
    &.btn-change-page-range {
      padding: 0 0 0 15px !important
    }

    &.btn-save-small {
      padding: 0 !important;
    }

    &.btn-delete-small {
      padding: 0 !important;
    }
  }
  .data-table th {
    &.col-checkbox {
      min-width: 50px !important;
    }
  }
}
@media screen and (max-width: 1024px) {
  .mail-title {
    padding: 10px 20px;
  }
  .page-main-content {
    padding: 20px 20px 50px 20px;
  }
  .modal {
    .modal-card {
      .change-role-office {
        .modal-field-content {
          padding: 20px 40px !important;
          min-height: 470px;
          max-height: 550px;
          overflow-y: auto;
        }
      }
    }
  }
}

</style>
<style lang="scss">
.mce-path, .mce-wordcount {
  display: none !important;
}
.ck.ck-editor__main {
  .ck-content {
    height: 200px !important;
  }
}
::v-deep {
  .mce-container  {
    box-shadow: none !important;
    border-color: #dbdbdb !important;
  }
}
</style>
